export const vars = {
  primaryFont: '"Inter", sans-serif',
  black: "#000000",
  white: "#FFFFFF",

  gray25: "#FDFDFC",
  gray50: "#F6F5F4",
  gray100: "#ECECE9",
  gray200: "#D3D3CF",
  gray300: "#B4BCCA",
  gray500: "#757570",
  gray600: "#63625F",
  gray700: "#535350",
  gray800: "#393937",
  gray900: "#232325",
  gray900A: "#21201C",
  gray950: "#161718",
  success50: "#ECFDF3",
  success200: "#ABEFC6",
  success700: "#067647",
  brand50: "#FDFDFC",
  brand200: "#BED7F9",
  brand300: "#90BBF4",
  brand500: "#3382EB",
  brand600: "#1568D5",

  primaryPurple25: "#FDFAFE",
  primaryPurple50: "#FAF1FD",
  primaryPurple100: "#F1DAFB",
  primaryPurple200: "#E8C3F8",
  primaryPurple300: "#C468EE",
  primaryPurple400: "#B23AE9",
  primaryPurple500: "#9B18D8",
  primaryPurple600: "#8300BF",
  primaryPurple700: "#5E008A",
  primaryPurple800: "#3B0057",
  primaryPurple900: "#2A003D",
  primaryPurple950: "#180024",

  primaryBlue25: "#FBFBFE",
  primaryBlue50: "#ECF4FD",
  primaryBlue100: "#DDDDF8",
  primaryBlue200: "#BED7F9",
  primaryBlue300: "#9696E9",
  primaryBlue400: "#6363DE",
  primaryBlue500: "#2C2CCE",
  primaryBlue600: "#1F1F93",
  primaryBlue700: "#1152A7",
  primaryBlue800: "#161637",
  primaryBlue900: "#0F0F24",
  primaryBlue950: "#070712",

  baseContainerBg: "#F5F7FB",
  primary500: "#2155BA",
  gray700A: "#4B5668",
  gray600A: "#818A98",
  gray400: "#9DA4AF",
  gray400A: "#9BA2B0",
  gray400B: "#969692",
  gray100A: "#F1F2F4",
  gray200A: "#D5D8DD",

  orange700: "#B93815",
  error700: "#B42318",
  error50: "#FEF3F2",

  borderRadius: 8,
  paperShadow: "0px 0px 1px rgba(0, 0, 0, 0.3), 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",

  // ADDITIONAL VARIABLES
  buttonShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  gray200S: "#E3E5E8",
};
