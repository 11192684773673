export const SidebarExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M1.6665 3.33398V16.6673H18.3332V3.33398H1.6665ZM16.6665 7.22565H14.5832V5.00065H16.6665V7.22565ZM14.5832 8.89232H16.6665V11.1173H14.5832V8.89232ZM3.33317 5.00065H12.9165V15.0007H3.33317V5.00065ZM14.5832 15.0007V12.7757H16.6665V15.0007H14.5832Z"
      fill="#757570"
    />
  </svg>
);

export const DataSetsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.16667 10.8333H5.83333V14.1667H9.16667V10.8333Z" fill="#757570" />
    <path d="M14.1667 10.8333H10.8333V14.1667H14.1667V10.8333Z" fill="#757570" />
    <path
      d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333Z"
      fill="#757570"
    />
    <path d="M9.16667 5.83333H5.83333V9.16667H9.16667V5.83333Z" fill="#757570" />
    <path d="M14.1667 5.83333H10.8333V9.16667H14.1667V5.83333Z" fill="#757570" />
  </svg>
);
export const NeuronsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.3335 10.0007C7.41683 10.0007 6.66683 10.7507 6.66683 11.6673C6.66683 12.584 7.41683 13.334 8.3335 13.334C9.25016 13.334 10.0002 12.584 10.0002 11.6673C10.0002 10.7507 9.25016 10.0007 8.3335 10.0007ZM5.00016 6.66732C4.0835 6.66732 3.3335 7.41732 3.3335 8.33398C3.3335 9.25065 4.0835 10.0007 5.00016 10.0007C5.91683 10.0007 6.66683 9.25065 6.66683 8.33398C6.66683 7.41732 5.91683 6.66732 5.00016 6.66732ZM5.00016 13.334C4.0835 13.334 3.3335 14.084 3.3335 15.0007C3.3335 15.9173 4.0835 16.6673 5.00016 16.6673C5.91683 16.6673 6.66683 15.9173 6.66683 15.0007C6.66683 14.084 5.91683 13.334 5.00016 13.334ZM15.0002 6.66732C15.9168 6.66732 16.6668 5.91732 16.6668 5.00065C16.6668 4.08398 15.9168 3.33398 15.0002 3.33398C14.0835 3.33398 13.3335 4.08398 13.3335 5.00065C13.3335 5.91732 14.0835 6.66732 15.0002 6.66732ZM11.6668 13.334C10.7502 13.334 10.0002 14.084 10.0002 15.0007C10.0002 15.9173 10.7502 16.6673 11.6668 16.6673C12.5835 16.6673 13.3335 15.9173 13.3335 15.0007C13.3335 14.084 12.5835 13.334 11.6668 13.334ZM15.0002 10.0007C14.0835 10.0007 13.3335 10.7507 13.3335 11.6673C13.3335 12.584 14.0835 13.334 15.0002 13.334C15.9168 13.334 16.6668 12.584 16.6668 11.6673C16.6668 10.7507 15.9168 10.0007 15.0002 10.0007ZM11.6668 6.66732C10.7502 6.66732 10.0002 7.41732 10.0002 8.33398C10.0002 9.25065 10.7502 10.0007 11.6668 10.0007C12.5835 10.0007 13.3335 9.25065 13.3335 8.33398C13.3335 7.41732 12.5835 6.66732 11.6668 6.66732ZM8.3335 3.33398C7.41683 3.33398 6.66683 4.08398 6.66683 5.00065C6.66683 5.91732 7.41683 6.66732 8.3335 6.66732C9.25016 6.66732 10.0002 5.91732 10.0002 5.00065C10.0002 4.08398 9.25016 3.33398 8.3335 3.33398Z"
      fill="#757570"
    />
  </svg>
);

export const MoreOptionsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 6.66732C10.9168 6.66732 11.6668 5.91732 11.6668 5.00065C11.6668 4.08398 10.9168 3.33398 10.0002 3.33398C9.0835 3.33398 8.3335 4.08398 8.3335 5.00065C8.3335 5.91732 9.0835 6.66732 10.0002 6.66732ZM10.0002 8.33398C9.0835 8.33398 8.3335 9.08398 8.3335 10.0007C8.3335 10.9173 9.0835 11.6673 10.0002 11.6673C10.9168 11.6673 11.6668 10.9173 11.6668 10.0007C11.6668 9.08398 10.9168 8.33398 10.0002 8.33398ZM10.0002 13.334C9.0835 13.334 8.3335 14.084 8.3335 15.0007C8.3335 15.9173 9.0835 16.6673 10.0002 16.6673C10.9168 16.6673 11.6668 15.9173 11.6668 15.0007C11.6668 14.084 10.9168 13.334 10.0002 13.334Z"
      fill="#757570"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.86339 10.5826L3.08339 7.80263L2.13672 8.74263L5.86339 12.4693L13.8634 4.4693L12.9234 3.5293L5.86339 10.5826Z" fill="#757570" />
  </svg>
);

export const CloseIcon = ({ fill = "#969692" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8337 5.34102L14.6587 4.16602L10.0003 8.82435L5.34199 4.16602L4.16699 5.34102L8.82533 9.99935L4.16699 14.6577L5.34199 15.8327L10.0003 11.1743L14.6587 15.8327L15.8337 14.6577L11.1753 9.99935L15.8337 5.34102Z"
      fill={fill}
    />
  </svg>
);

export const CaretIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.825 6.91211L10 10.7288L6.175 6.91211L5 8.08711L10 13.0871L15 8.08711L13.825 6.91211Z" fill="#757570" />
  </svg>
);

export const TourIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3334 2.66732H4.00008V1.33398H2.66675V14.6673H4.00008V9.33398H13.3334L12.0001 6.00065L13.3334 2.66732ZM10.7601 6.49398L11.3601 8.00065H4.00008V4.00065H11.3667L10.7667 5.50732L10.5667 6.00065L10.7601 6.49398ZM8.66675 6.00065C8.66675 6.73398 8.06675 7.33398 7.33341 7.33398C6.60008 7.33398 6.00008 6.73398 6.00008 6.00065C6.00008 5.26732 6.60008 4.66732 7.33341 4.66732C8.06675 4.66732 8.66675 5.26732 8.66675 6.00065Z"
      fill="#757570"
    />
  </svg>
);

export const DataSourceIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 3.99935H7.99992L6.66659 2.66602H2.66659C1.93325 2.66602 1.33992 3.26602 1.33992 3.99935L1.33325 11.9993C1.33325 12.7327 1.93325 13.3327 2.66659 13.3327H13.3333C14.0666 13.3327 14.6666 12.7327 14.6666 11.9993V5.33268C14.6666 4.59935 14.0666 3.99935 13.3333 3.99935ZM13.3333 11.9993H2.66659V3.99935H6.11325L7.44659 5.33268H13.3333V11.9993ZM11.9999 7.99935H3.99992V6.66602H11.9999V7.99935ZM9.33325 10.666H3.99992V9.33268H9.33325V10.666Z"
      fill="#757570"
    />
  </svg>
);

export const ConnectionsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 4.66667C10.6667 3.19333 9.47333 2 8 2C6.52667 2 5.33333 3.19333 5.33333 4.66667C5.33333 6.14 6.52667 7.33333 8 7.33333C9.47333 7.33333 10.6667 6.14 10.6667 4.66667ZM8 6C7.26667 6 6.66667 5.4 6.66667 4.66667C6.66667 3.93333 7.26667 3.33333 8 3.33333C8.73333 3.33333 9.33333 3.93333 9.33333 4.66667C9.33333 5.4 8.73333 6 8 6ZM4.66667 8.66667C3.19333 8.66667 2 9.86 2 11.3333C2 12.8067 3.19333 14 4.66667 14C6.14 14 7.33333 12.8067 7.33333 11.3333C7.33333 9.86 6.14 8.66667 4.66667 8.66667ZM4.66667 12.6667C3.93333 12.6667 3.33333 12.0667 3.33333 11.3333C3.33333 10.6 3.93333 10 4.66667 10C5.4 10 6 10.6 6 11.3333C6 12.0667 5.4 12.6667 4.66667 12.6667ZM11.3333 8.66667C9.86 8.66667 8.66667 9.86 8.66667 11.3333C8.66667 12.8067 9.86 14 11.3333 14C12.8067 14 14 12.8067 14 11.3333C14 9.86 12.8067 8.66667 11.3333 8.66667ZM11.3333 12.6667C10.6 12.6667 10 12.0667 10 11.3333C10 10.6 10.6 10 11.3333 10C12.0667 10 12.6667 10.6 12.6667 11.3333C12.6667 12.0667 12.0667 12.6667 11.3333 12.6667Z"
      fill="#757570"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6666 6.33398H9.99992V2.33398H5.99992V6.33398H3.33325L7.99992 11.0007L12.6666 6.33398ZM7.33325 7.66732V3.66732H8.66659V7.66732H9.44659L7.99992 9.11398L6.55325 7.66732H7.33325ZM3.33325 12.334H12.6666V13.6673H3.33325V12.334Z"
      fill="#757570"
    />
  </svg>
);

export const CiteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 1.33398H4.00008C3.26675 1.33398 2.66675 1.93398 2.66675 2.66732V13.334C2.66675 14.0673 3.26675 14.6673 4.00008 14.6673H12.0001C12.7334 14.6673 13.3334 14.0673 13.3334 13.334V2.66732C13.3334 1.93398 12.7334 1.33398 12.0001 1.33398ZM6.00008 2.66732H7.33341V6.00065L6.66675 5.50065L6.00008 6.00065V2.66732ZM12.0001 13.334H4.00008V2.66732H4.66675V8.66732L6.66675 7.16732L8.66675 8.66732V2.66732H12.0001V13.334Z"
      fill="#757570"
    />
  </svg>
);

export const ContributeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1C4.1325 1 1 4.1325 1 8C1 11.0975 3.00375 13.7137 5.78625 14.6413C6.13625 14.7025 6.2675 14.4925 6.2675 14.3088C6.2675 14.1425 6.25875 13.5913 6.25875 13.005C4.5 13.3288 4.045 12.5763 3.905 12.1825C3.82625 11.9812 3.485 11.36 3.1875 11.1937C2.9425 11.0625 2.5925 10.7387 3.17875 10.73C3.73 10.7212 4.12375 11.2375 4.255 11.4475C4.885 12.5062 5.89125 12.2088 6.29375 12.025C6.355 11.57 6.53875 11.2638 6.74 11.0887C5.1825 10.9137 3.555 10.31 3.555 7.6325C3.555 6.87125 3.82625 6.24125 4.2725 5.75125C4.2025 5.57625 3.9575 4.85875 4.3425 3.89625C4.3425 3.89625 4.92875 3.7125 6.2675 4.61375C6.8275 4.45625 7.4225 4.3775 8.0175 4.3775C8.6125 4.3775 9.2075 4.45625 9.7675 4.61375C11.1063 3.70375 11.6925 3.89625 11.6925 3.89625C12.0775 4.85875 11.8325 5.57625 11.7625 5.75125C12.2087 6.24125 12.48 6.8625 12.48 7.6325C12.48 10.3187 10.8438 10.9137 9.28625 11.0887C9.54 11.3075 9.75875 11.7275 9.75875 12.3837C9.75875 13.32 9.75 14.0725 9.75 14.3088C9.75 14.4925 9.88125 14.7113 10.2312 14.6413C11.6209 14.1721 12.8284 13.279 13.6839 12.0877C14.5393 10.8963 14.9996 9.46668 15 8C15 4.1325 11.8675 1 8 1Z"
      fill="#757570"
    />
  </svg>
);

export const ContactIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66675 15.5307V13.1307C4.32675 12.9574 1.66675 10.184 1.66675 6.80404C1.66675 3.3107 4.50675 0.470703 8.00008 0.470703C11.4934 0.470703 14.3334 3.3107 14.3334 6.80404C14.3334 10.104 12.0401 13.424 8.62008 15.0707L7.66675 15.5307ZM8.00008 1.80404C5.24008 1.80404 3.00008 4.04404 3.00008 6.80404C3.00008 9.56404 5.24008 11.804 8.00008 11.804H9.00008V13.3374C11.4267 11.804 13.0001 9.28404 13.0001 6.80404C13.0001 4.04404 10.7601 1.80404 8.00008 1.80404ZM7.33341 9.4707H8.66675V10.804H7.33341V9.4707ZM8.66675 8.4707H7.33341C7.33341 6.30404 9.33341 6.4707 9.33341 5.13737C9.33341 4.40404 8.73341 3.80404 8.00008 3.80404C7.26675 3.80404 6.66675 4.40404 6.66675 5.13737H5.33341C5.33341 3.66404 6.52675 2.4707 8.00008 2.4707C9.47341 2.4707 10.6667 3.66404 10.6667 5.13737C10.6667 6.80404 8.66675 6.9707 8.66675 8.4707Z"
      fill="#757570"
    />
  </svg>
);

export const ViewerSettings = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 14.1667V15.8333H7.5V14.1667H2.5ZM2.5 4.16667V5.83333H10.8333V4.16667H2.5ZM10.8333 17.5V15.8333H17.5V14.1667H10.8333V12.5H9.16667V17.5H10.8333ZM5.83333 7.5V9.16667H2.5V10.8333H5.83333V12.5H7.5V7.5H5.83333ZM17.5 10.8333V9.16667H9.16667V10.8333H17.5ZM12.5 7.5H14.1667V5.83333H17.5V4.16667H14.1667V2.5H12.5V7.5Z"
      fill="#535350"
    />
  </svg>
);

export const LinkIcon = ({ fill = "#757570" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1665 5.83398H10.8332V7.50065H14.1665C15.5415 7.50065 16.6665 8.62565 16.6665 10.0007C16.6665 11.3757 15.5415 12.5007 14.1665 12.5007H10.8332V14.1673H14.1665C16.4665 14.1673 18.3332 12.3007 18.3332 10.0007C18.3332 7.70065 16.4665 5.83398 14.1665 5.83398ZM9.1665 12.5007H5.83317C4.45817 12.5007 3.33317 11.3757 3.33317 10.0007C3.33317 8.62565 4.45817 7.50065 5.83317 7.50065H9.1665V5.83398H5.83317C3.53317 5.83398 1.6665 7.70065 1.6665 10.0007C1.6665 12.3007 3.53317 14.1673 5.83317 14.1673H9.1665V12.5007ZM6.6665 9.16732H13.3332V10.834H6.6665V9.16732Z"
      fill={fill}
    />
  </svg>
);

export const DownIcon = ({ color }: { color?: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.175 6.91211L10 10.7288L13.825 6.91211L15 8.08711L10 13.0871L5 8.08711L6.175 6.91211Z" fill={color ? color : "#63625F"} />
  </svg>
);

export const AddIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8332 10.8327H10.8332V15.8327H9.1665V10.8327H4.1665V9.16602H9.1665V4.16602H10.8332V9.16602H15.8332V10.8327Z" fill="#63625F" />
  </svg>
);

export const ChemicalSynapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
    <path
      d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM21.3536 4.35355C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464466C17.9763 0.269204 17.6597 0.269204 17.4645 0.464466C17.2692 0.659728 17.2692 0.976311 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53553C17.6597 7.7308 17.9763 7.7308 18.1716 7.53553L21.3536 4.35355ZM1 4.5H21V3.5H1V4.5Z"
      fill="#63625F"
    />
  </svg>
);

export const GapJunctionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="6" viewBox="0 0 22 6" fill="none">
    <path d="M1 3.06061L8.5 3L9 1L10 5L11 1L12 5L12.5 3L21 3.06061" stroke="#63625F" strokeLinecap="round" />
  </svg>
);

export const AlignLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M5.33333 8.66732L5.33333 6.66732L12 6.66732L12 5.33398L5.33333 5.33398L5.33333 3.33398L2.66667 6.00065L5.33333 8.66732ZM0 11.334L1.33333 11.334L1.33333 0.667318L1.27199e-07 0.667318L0 11.334Z"
      fill="#757570"
    />
  </svg>
);

export const AlignRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M6.66667 3.33268L6.66667 5.33268L-2.03986e-07 5.33268L-2.62268e-07 6.66601L6.66667 6.66601L6.66667 8.66601L9.33333 5.99935L6.66667 3.33268ZM12 0.666016L10.6667 0.666016L10.6667 11.3327L12 11.3327L12 0.666016Z"
      fill="#757570"
    />
  </svg>
);

export const AlignTopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M5.33366 7.33333H7.33366V14H8.66699V7.33333H10.667L8.00033 4.66667L5.33366 7.33333ZM2.66699 2V3.33333H13.3337V2H2.66699Z" fill="#757570" />
  </svg>
);

export const AlignBottomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10.667 8.66667H8.66699V2H7.33366V8.66667H5.33366L8.00033 11.3333L10.667 8.66667ZM2.66699 12.6667V14H13.3337V12.6667H2.66699Z" fill="#757570" />
  </svg>
);

export const DistributeHorizontallyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.66634 14.6673H1.33301V1.33398H2.66634V14.6673ZM14.6663 1.33398H13.333V14.6673H14.6663V1.33398ZM8.99967 4.66732H6.99967V11.334H8.99967V4.66732Z"
      fill="#757570"
    />
  </svg>
);

export const DistributeVerticallyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M14.6663 1.33398V2.66732H1.33301V1.33398H14.6663ZM4.66634 7.00065V9.00065H11.333V7.00065H4.66634ZM1.33301 13.334V14.6673H14.6663V13.334H1.33301Z"
      fill="#757570"
    />
  </svg>
);
