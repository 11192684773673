import { Vector3 } from "three";

export const CAMERA_FOV = 50;
export const CAMERA_POSITION = new Vector3(0, 0, 50);
export const CAMERA_NEAR = 0.1;
export const CAMERA_FAR = 2000;

export const LIGHT_1_COLOR = 0x404040;
export const LIGHT_2_COLOR = 0xccccff;
export const LIGHT_2_POSITION = new Vector3(-1, 0.75, -0.5);

export const SCENE_BACKGROUND = "0xd9d8d4";

export const OUTLINE_THICKNESS = 0.05;
export const OUTLINE_COLOR = "hotpink";
